var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-type6 my-2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "div",
        {
          staticClass: "swiperBox d-flex",
          style: { height: _vm.swiperHeight + "px" }
        },
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "myTab px-4 py-0",
              attrs: { options: _vm.tabOption }
            },
            _vm._l(_vm.data.data.navbar, function(item, index) {
              return _c(
                "swiper-slide",
                { key: item._id, staticStyle: { width: "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "game__item--card cursor-pointer",
                      class: {
                        "swiper-slide-active": _vm.tabActiveIndex === index
                      },
                      on: {
                        click: function($event) {
                          return _vm.getGameType(index)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "game-card d-flex justify-center flex-column align-center pt-1"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + item.nav_icon_light,
                              height: "20"
                            }
                          }),
                          _c(
                            "strong",
                            {
                              class: [
                                _vm.tabActiveIndex === index
                                  ? "white--text"
                                  : "subtitle--text"
                              ]
                            },
                            [_vm._v(" " + _vm._s(item.game_type))]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper px-4 py-0 pl-0",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.NewItemContent, function(item) {
              return _c(
                "swiper-slide",
                {
                  key: item._id,
                  class: item.status === "0" ? "maintenanceBox" : "",
                  staticStyle: { width: "auto" }
                },
                [
                  item.status == "0" ? _c("maintenance") : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "game__item--card cursor-pointer d-flex justify-center flex-column align-center",
                      on: {
                        click: function($event) {
                          return _vm.lauchGame(
                            item.game_code,
                            item.hyperlink,
                            item.class
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "itemContent d-flex flex-column justify-center align-center"
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + item.logo,
                              height: "40px"
                            }
                          }),
                          _c("strong", [_vm._v(_vm._s(item.title))]),
                          _c("div", { staticClass: "subtitle--text" }, [
                            _vm._v(" " + _vm._s(item.game_type) + " ")
                          ])
                        ]
                      ),
                      _c("img", {
                        attrs: {
                          src: _vm.baseImgUrl + item.image_h5_2,
                          width: "100%"
                        }
                      }),
                      _c("span", { staticClass: "secondary--text" }, [
                        _vm._v(_vm._s(item.class))
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }